@font-face {
  font-family: 'UHCSerifHeadline-Semibold';
  src: url('src/assets/UHC-Fonts/UHC-Serif-OTF/UHCSerifHeadline-Semibold.otf');
}
@font-face {
  font-family: 'UHC2020Sans-Medium';
  src: url('src/assets/UHC-Fonts/UHC Sans-OTF/UHC2020Sans-Medium.otf');
}
@font-face {
  font-family: 'UHC2020Sans-Bold';
  src: url('src/assets/UHC-Fonts/UHC Sans-OTF/UHC2020Sans-Bold.otf');
}
@font-face {
  font-family: 'UHC2020Sans';
  src: url('src/assets/UHC-Fonts/UHC Sans-OTF/UHC2020Sans-Regular.otf');
}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    -webkit-text-size-adjust: auto !important;
}

html {
  font-size: 2vh;
}

.dark-blue-color {
    background-color : #002060;
}

/* Override bootstrap links underline */
a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline solid #0d6efd 2px !important;
}

.content-font-size {
  font-size: small;
  margin: 20px 0;
}
@media(min-width:576px){

}
@media(min-width:768px){

}
@media(min-width:992px){

}
@media(min-width:1200px){
 
}

@media(min-width:1400px) {
    
}

router-outlet + * {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: UHC2020Sans-Medium;
}

h1, h2, h3, h4, h5 {
  font-family: UHCSerifHeadline-Semibold;
}


